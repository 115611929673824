import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';
import { getToken } from '../authService';
import AddDestination from './AddDestination'; 

const api_url = `${API_URL}/destination/getAllDestinations`;

const Destinations = () => {
  const [destinations, setDestinations] = useState([]); 
  const [showModal, setShowModal] = useState(false); 

  const fetchDestinations = async () => {
    try {
      const token = getToken();
      console.log('Token:', token); // Debug: Check token value
      const response = await axios.get(api_url, {
        headers: {
          'Authorization': `${token}`, 
        },
      });
      console.log('API Response:', response.data); // Debug: Check API response
      setDestinations(response.data); 
    } catch (error) {
      console.error('Error fetching destinations:', error.message);
    }
  };

  useEffect(() => {
    fetchDestinations();
  }, []);

  const handleAddDestination = (newDestination) => {
    setDestinations([...destinations, newDestination]); 
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-xl font-bold mb-4">Destinations</h2>

      {destinations.length === 0 ? (
        <p>No destinations available.</p>
      ) : (
        <ul className="space-y-4">
          {destinations.map(destination => (
            <li key={destination.destination_id} className="flex items-center space-x-4">
              {destination.destination_image ? (
                <img
                  src={`https://songstarktours.s3.ap-northeast-2.amazonaws.com/${destination.destination_image}`}
                  alt={destination.destination_name}
                  className="w-24 h-24 object-cover rounded-lg"
                  style={{ maxWidth: '100px', maxHeight: '100px' }} // Adjust image size
                />
              ) : (
                <div className="w-24 h-24 bg-gray-200 flex items-center justify-center rounded-lg">
                  <span className="text-gray-500">No Image</span>
                </div>
              )}
              <div>
                <h3 className="font-bold">{destination.destination_name}</h3>
                <h3 className="font-bold">{destination.destination_description}</h3>
              </div>
            </li>
          ))}
        </ul>
      )}

      <button
        onClick={() => setShowModal(true)}
        className="mt-4 bg-green-800 text-white p-2 rounded-lg"
      >
        Add Destination
      </button>

      {showModal && (
        <AddDestination
          onClose={() => setShowModal(false)}
          onAddDestination={handleAddDestination} // Pass handler for adding new destination
        />
      )}
    </div>
  );
};

export default Destinations;
