import React from 'react'
import UserTable from '../components/UserTable';
import Layout from '../components/Layout';
const Users = () => {
  return (
  <Layout>
      <div className="flex-1">
        <UserTable />
      </div>
      </Layout>
  );
};

export default Users;