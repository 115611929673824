// Header.js
import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt, faCog} from '@fortawesome/free-solid-svg-icons';
import { UserContext } from "../components/UserContext";
import { Link } from 'react-router-dom';


const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const { user, logout } = useContext(UserContext);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const openProfileModal = () => {
    setProfileModalOpen(true);
    setDropdownOpen(false);
  };

  const closeProfileModal = () => {
    setProfileModalOpen(false);
  };

  return (
    <>
      <div className="fixed top-0 left-64 right-0 h-16 bg-white z-20 flex items-center justify-between px-4 py-2">
        <div>
          <h2 className="text-l font-semibold font-roboto text-black">Hello, Admin</h2>
          <p className="text-gray-700 text-sm">Have a nice day</p>
        </div>
        <div className="relative">
          <div className="flex items-center cursor-pointer" onClick={toggleDropdown}>
            <div className="text-right">
              <h4 className="text-lg text-black font-semibold">{user?.email || "Guest"}</h4>
              <span className="text-sm text-gray-700">{user?.firstName || "Admin"}</span>
            </div>
            <div className="ml-4">
              <div className="w-10 h-10 rounded-full bg-gray-300"></div>
            </div>
          </div>

          {dropdownOpen && (
            <div className="absolute right-0 mt-2 w-56 bg-white border rounded shadow-lg z-10">
              <ul>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
                  onClick={openProfileModal}
                >
                  <FontAwesomeIcon icon={faUser} className="mr-2 text-gray-700" />
                  Profile
                </li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center">
                     <Link to="/account" className="flex items-center">
                  <FontAwesomeIcon icon={faCog} className="mr-2 text-gray-700" />
                   Settings
                     </Link>
                   </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
                  onClick={logout}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} className="mr-2 text-gray-700" />
                  Sign Out
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      {isProfileModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-20">
          <div className="bg-white p-6 rounded shadow-lg w-96">
            <h2 className="text-2xl font-bold mb-4">Profile</h2>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Username</label>
              <input
                type="text"
                value={user?.email || ""}
                readOnly
                className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-green-800"
              />
            </div>
            <div className="text-right">
              <button
                className="bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline"
                onClick={closeProfileModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
