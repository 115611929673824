
export const activeTours = [
    { id: 1, name: 'Safari Adventure', startDate: '10 Sep, 2024', customers: 25 },
    { id: 2, name: 'City Exploration', startDate: '15 Sep, 2024', customers: 18 },
  ];
  
  export const upcomingTours = [
    { id: 1, name: 'Mountain Hiking', startDate: '20 Sep, 2024' },
    { id: 2, name: 'Beach Holiday', startDate: '25 Sep, 2024' },
    { id: 3, name: 'Historical Sites Tour', startDate: '30 Sep, 2024' },
  ];
  