import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import ActiveTours from '../components/ActiveTours';
import UpcomingTours from '../components/UpcomingTours';
import Activities from '../components/Activities';
import Destinations from '../components/Destinations';
import axios from 'axios';
import { API_URL } from '../variables'; 
import { activeTours, upcomingTours } from '../assets/toursdata';
import { getToken } from '../authService'; // Import the getToken function
import { Link } from 'react-router-dom';

function Dashboard() {
  const [pendingBookingsCount, setPendingBookingsCount] = useState(0);
  const [pendingReservationCount, setPendingReservationCount] = useState(0);
  const [totalTours, setTotalTours] = useState(0);
  const [contactRequests, setContactRequests] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const token = getToken(); 

        const headers = {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        };
        const { data: packages } = await axios.get(`${API_URL}/package/getAllPackages`, { headers });
        setTotalTours(packages.length);
        const { data: customReservations } = await axios.get(`${API_URL}/custom_reservations/getAllReservations`, { headers });
        setPendingBookingsCount(customReservations.length);
        const { data: reservations } = await axios.get(`${API_URL}/reservations/getAllReservations`, { headers });
        setPendingReservationCount(reservations.length);
        const { data: contacts } = await axios.get(`${API_URL}/contacts/getAllContacts`, { headers });
        setContactRequests(contacts.length);

      } catch (err) {
        setError('Failed to load dashboard data.');
        console.error(err);
      }
    };

    fetchDashboardData();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Layout>
      <div className="mx-auto p-6">
        <h1 className="text-3xl text-green-900 font-semibold mb-6">Users Dashboard</h1>
        <div className="grid grid-cols-4 gap-6 mb-8">
        <Link to="/tours">
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-base font-medium text-gray-600">Total Tours</h2>
            <p className="text-green-800 text-3xl mt-3 font-bold">{totalTours}</p>
          </div>
          </Link>
          <Link to="/bookings">
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-base font-medium text-gray-600">Pending Bookings</h2>
            <p className="text-green-800 text-3xl mt-3 font-bold">{pendingBookingsCount}</p>
          </div>
          </Link>
          <Link to="/bookings">
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-base font-medium text-gray-600">Reserved Tickets</h2>
            <p className="text-green-800 text-3xl mt-3 font-bold">{pendingReservationCount}</p>
          </div>
          </Link>
          <Link to="/contact">
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-base font-medium text-gray-600">Contact Requests</h2>
            <p className="text-green-800 text-3xl mt-3 font-bold">{contactRequests}</p>
          </div>
          </Link>
        </div>
        <div className="space-y-8">
        <Activities />
        <Destinations />
          <ActiveTours tours={activeTours} />
          <UpcomingTours tours={upcomingTours} />

        </div>
      </div>
    </Layout>
  );
}

export default Dashboard;
