import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';

const api_url = `${API_URL}/custom_reservations/getAllReservations`;

const activities = [
  { id: 1, name: "Gorilla Tracking" },
  { id: 2, name: "Chimpanzee Tracking" },
  { id: 3, name: "Golden Monkey Tracking" },
  { id: 4, name: "Games Drives" },
  { id: 5, name: "Cultural Safaris" },
  { id: 6, name: "Easy to Moderate Hiking" },
  { id: 7, name: "Moderate to Difficult Hiking" },
  { id: 8, name: "Boat trips" },
  { id: 9, name: "Visiting Community Projects" },
];

const PlannedTrips = ({ setPendingCount }) => {
  const [reservations, setReservations] = useState([]);

  const getInterestNames = (interestIds) => {
    return interestIds
      .map((id) => {
        const activity = activities.find((activity) => activity.id === id);
        return activity ? activity.name : "Unknown";
      })
      .join(", ");
  };

  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const response = await axios.get(api_url, {
          headers: { "Content-Type": "application/json" },
        });
        console.log('Fetched reservations:', response.data);
        setReservations(response.data);
        setPendingCount(response.data.length);
      } catch (error) {
        console.error('Error fetching trips:', error.message);
      }
    };
    fetchTrips();
  }, [setPendingCount]);

  return (
    <div className="mb-6">
       <h1 className="text-2xl font-bold text-green-800 mb-6">Planned Trips</h1>
      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="min-w-full bg-white border border-gray-300">
          <thead className="bg-green-800 text-white" >
            <tr className="bg-green-800 text-white text-base">
            <th className="px-4 py-2 text-left">No</th>
              <th className="border border-gray-300 p-2">First Name</th>
              <th className="border border-gray-300 p-2">Last Name</th>
              <th className="border border-gray-300 p-2">Email</th>
              <th className="border border-gray-300 p-2">Phone Number</th>
              <th className="border border-gray-300 p-2">Interests</th>
              <th className="border border-gray-300 p-2">Arrival Date</th>
              <th className="border border-gray-300 p-2">Departure Date</th>
              <th className="border border-gray-300 p-2">Reservation Date</th>
            </tr>
          </thead>
          <tbody>
            {reservations.length > 0 ? (
              reservations.map((reservation, index) => (
                <tr key={index} className="text-base border-t border-gray-300">
                   <td className="px-4 py-2">{index + 1}</td>
                  <td className="p-2">{reservation.reservation_first_name || 'None'}</td>
                  <td className="p-2">{reservation.reservation_last_name || 'None'}</td>
                  <td className="p-2">{reservation.reservation_email || 'None'}</td>
                  <td className="p-2">{reservation.reservation_phone || 'None'}</td>
                  <td className="p-2">
                    {reservation.reservation_interests?.length > 0
                      ? getInterestNames(reservation.activity_ids)
                      : "None"}
                  </td>
                  <td className="p-2">{reservation.arrival_date || 'None'}</td>
                  <td className="p-2">{reservation.departure_date || 'None'}</td>
                  <td className="p-2">{reservation.reservation_date || 'None'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center p-2 border-t border-gray-300">
                  No trips available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlannedTrips;
