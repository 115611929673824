import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';
import { getToken } from '../authService';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Layout from './Layout';

const api_url = `${API_URL}/package/updatePackage`;
const TourEditForm = ({onSave, onClose }) => {
  const navigate = useNavigate();
  const { packageId } = useParams();
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    package_title: '',
    package_summary: '',
    package_image: '',
    package_images: [],
    package_location: '',
    destination_id: '',
    activities: [],
    tour_type: [],
    package_days: '',
    package_nights: '',
    package_price: '',
    package_itinerary: [{ title: '', content: '' }],
    package_inclusions: [],
    package_exclusions: [],
    package_requirements: [],
    package_minage: '',
    package_maxpeople: '',
    free_cancelation: false,
    best_price_guarantee: false,
    is_popular: false,
  });

  useEffect(() => {
    console.log('Fetched packageId:', packageId); 
    const fetchTourData = async () => {
      try {
        const token = getToken();
        const { data } = await axios.get(`${API_URL}/package/getPackagesById/${packageId}`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        setFormData(data);
      } catch (error) {
        setError('Failed to load tour data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchTourData();
  }, [packageId]);

  const handleChange = async (event) => {
    const { name, value, type, files } = event.target;

    if (type === 'file') {
      const file = files[0];
      if (file) {
        try {
          const uploadedFileName = await handleImageUpload(file);
          if (uploadedFileName) {
            setFormData(prevFormData => ({
              ...prevFormData,
              [name]: uploadedFileName,
            }));
          }
        } catch (error) {
          setError('Failed to upload image. Please try again.');
        }
      }
    } else if (name.startsWith('itinerary[')) {
      const index = parseInt(name.match(/\d+/)[0], 10);
      const field = name.includes('title') ? 'title' : 'content';
      setFormData(prevFormData => {
        const updatedItinerary = [...prevFormData.package_itinerary];
        updatedItinerary[index] = { ...updatedItinerary[index], [field]: value };
        return { ...prevFormData, package_itinerary: updatedItinerary };
      });
    } else if (name.endsWith('[]')) {
      const fieldName = name.replace('[]', '');
      setFormData(prevFormData => ({
        ...prevFormData,
        [fieldName]: value.split(',').map(item => item.trim()),
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: type === 'checkbox' ? event.target.checked : value,
      }));
    }
  };

  const handleFileChange = async (files) => {
    const fileArray = Array.from(files);
    const uploadedFileNames = [];
    try {
      const uploadPromises = fileArray.map(file => handleImageUpload(file));
      const results = await Promise.all(uploadPromises);
      results.forEach(result => {
        if (result) uploadedFileNames.push(result);
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        package_images: uploadedFileNames,
      }));
    } catch (error) {
      setError('Failed to upload images. Please try again.');
    }
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    try {
      const token = getToken();
      const { data, status } = await axios.post(`${API_URL}/upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      if (status === 200) return data.file_name;
      throw new Error('Image upload failed');
    } catch (error) {
      setError('Failed to upload image. Please try again.');
    }
  };

  const addItineraryItem = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      package_itinerary: [...prevFormData.package_itinerary, { title: '', content: '' }]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccessMessage('');

    try {
      const token = getToken();
      if (!token) throw new Error('No authorization token found.');

      const formattedData = {
        ...formData,
        activities: JSON.stringify(formData.activities),
        best_price_guarantee: formData.best_price_guarantee,
        destination_id: formData.destination_id,
        free_cancelation: formData.free_cancelation,
        is_popular: formData.is_popular,
        package_days: Number(formData.package_days),
        package_exclusions: JSON.stringify(formData.package_exclusions),
        package_image: formData.package_image,
        package_images: JSON.stringify(formData.package_images),
        package_inclusions: JSON.stringify(formData.package_inclusions),
        package_itinerary: JSON.stringify(formData.package_itinerary),
        package_location: formData.package_location,
        package_maxpeople: Number(formData.package_maxpeople),
        package_minage: Number(formData.package_minage),
        package_nights: Number(formData.package_nights),
        package_price: Number(formData.package_price),
        package_requirements: JSON.stringify(formData.package_requirements),
        package_summary: formData.package_summary,
        tour_type: JSON.stringify(formData.tour_type),
      };

      const response = await axios.post(api_url, formattedData, {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setSuccessMessage('Package updated successfully!');
        if (onSave) {
          onSave(formattedData);
        }
        navigate('/tours'); 
      } else {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
    }  catch (error) {
      if (!error.response || error.response.status !== 200) {
        setError(error.response?.data?.message || 'Failed to update package. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="relative max-w-3xl mx-auto bg-white shadow-lg rounded-lg flex-grow overflow-y-auto p-4 space-y-4" style={{ maxHeight: 'calc(100vh - 100px)' }}>
        {/* Close Button */}
        <button
          type="button"
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>

        {/* Loading State */}
        {loading ? (
          <div>Loading...</div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="space-y-6">
              <h2 className="text-xl font-semibold text-gray-700">Edit Package Details</h2>

              {/* Package Title */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Package Title</label>
                <input
                  type="text"
                  name="package_title"
                  value={formData.package_title}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>

              {/* Package Summary */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Package Summary</label>
                <textarea
                  name="package_summary"
                  value={formData.package_summary}
                  onChange={handleChange}
                  rows="3"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>

              {/* Package Image */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Package Image</label>
                <input
                  type="file"
                  name="package_image"
                  onChange={handleChange}
                  className="mt-1 block w-full"
                />
              </div>

              {/* Additional Images */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Additional Images</label>
                <input
                  type="file"
                  multiple
                  onChange={(e) => handleFileChange(e.target.files)}
                  className="mt-1 block w-full"
                />
              </div>

              {/* Location */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Location</label>
                <input
                  type="text"
                  name="package_location"
                  value={formData.package_location}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>

              {/* Destination ID */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Destination ID</label>
                <input
                  type="text"
                  name="destination_id"
                  value={formData.destination_id}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
              <div>
              <label className="block text-sm font-medium text-gray-700">Package Min Age</label>
              <input
                type="number"
                name="package_minage"
                value={formData.package_minage}
                onChange={handleChange}
                className="border rounded p-2 w-full"
                placeholder="Enter minimum age"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Max People</label>
              <input
                type="number"
                name="package_maxpeople"
                value={formData.package_maxpeople}
                onChange={handleChange}
                className="border rounded p-2 w-full"
                placeholder="Enter maximum number of people"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Tour Type (Comma Separated)</label>
              <input
                type="text"
                name="tour_type[]"
                value={formData.tour_type}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>

              {/* Days */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Days</label>
                <input
                  type="number"
                  name="days"
                  value={formData.package_days}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
              <div>
              <label className="block text-sm font-medium text-gray-700">Package Nights</label>
              <input
                type="number"
                name="package_nights"
                value={formData.package_nights}
                onChange={handleChange}
                className="border rounded p-2 w-full"
                placeholder="Enter number of nights"
              />
            </div>

              {/* Price */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Price</label>
                <input
                  type="number"
                  name="price"
                  value={formData.package_price}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>

              {/* Rating */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Rating</label>
                <input
                  type="number"
                  name="rating"
                  value={formData.rating}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
              {/* {Itinerary} */}
              <div>
  <label className="block text-sm font-medium text-gray-700">Itinerary</label>
  {formData.package_itinerary.map((item, index) => (
    <div key={index} className="mb-4">
      <div className="mb-2">
        <label className="block text-sm font-medium text-gray-700">Title {index + 1}</label>
        <input
          type="text"
          name={`itinerary[${index}].title`}
          value={item.title}
          onChange={handleChange}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Content {index + 1}</label>
        <textarea
          name={`itinerary[${index}].content`}
          value={item.content}
          onChange={handleChange}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          rows="4"
        />
      </div>
    </div>
  ))}
  <button
    type="button"
    onClick={addItineraryItem}
    className="mt-4 bg-green-500 text-white py-2 px-4 rounded"
  >
    Add Itinerary Item
  </button>
</div>

              {/* Special */}
              <div>
              <label className="block text-sm font-medium text-gray-700">Requirements (Comma Separated)</label>
              <input
                type="text"
                name="package_requirements[]"
                value={formData.package_requirements}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                name="free_cancelation"
                checked={formData.free_cancelation}
                onChange={handleChange}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm font-medium text-gray-700">Free Cancelation</label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                name="best_price_guarantee"
                checked={formData.best_price_guarantee}
                onChange={handleChange}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm font-medium text-gray-700">Best Price Guarantee</label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                name="is_popular"
                checked={formData.is_popular}
                onChange={handleChange}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm font-medium text-gray-700">Mark as Popular</label>
            </div>


              {/* Inclusions */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Inclusions</label>
                <textarea
                  name="inclusions"
                  value={formData.package_exclusions}
                  onChange={handleChange}
                  rows="2"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>

              {/* Exclusions */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Exclusions</label>
                <textarea
                  name="exclusions"
                  value={formData.package_exclusions}
                  onChange={handleChange}
                  rows="2"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>

              {/* Buttons */}
              <div className="flex justify-between mt-4">
                <button
                  type="button"
                  onClick={onClose}
                  className="py-2 px-4 bg-gray-500 text-white rounded-md hover:bg-gray-600"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="py-2 px-4 bg-green-500 text-white rounded-md hover:bg-green-600"
                >
                  {loading ? 'Saving...' : 'Save Changes'}
                </button>
              </div>
            </div>

            {/* Error and Success Messages */}
            {error && (
              <div className="text-red-600 mt-4">{error}</div>
            )}
            {successMessage && (
              <div className="text-green-600 mt-4">{successMessage}</div>
            )}
          </form>
        )}
      </div>
    </Layout>
  );
};

export default TourEditForm;