import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from "../variables";
import { FaEdit, FaTrash } from 'react-icons/fa';

const api_url = API_URL + '/reservations/getAllReservations';

const ReservedTickets = ({ setCount }) => {
  const [reservations, setReservations] = useState([]);

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const response = await axios.get(`${api_url}`, {
          headers: { "Content-Type": "application/json" },
        });
        console.log(response.data);
        setReservations(response.data);
        setCount(response.data.length);
      } catch (error) {
        console.error('Error fetching reservations:', error.response || error.message);
      }
    };
    fetchReservations();
  }, [setCount]);

  return (
    <div className="mx-auto  bg-gray-50">
      <h1 className="text-2xl font-bold text-green-800 mb-6">Reserved Tickets</h1>
      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-green-800 text-white">
            <tr>
            <th className="px-4 py-2 text-left">No</th>
              <th className="px-4 py-2 text-left">Name</th>
              <th className="px-4 py-2 text-left">Email</th>
              <th className="px-4 py-2 text-left">Phone</th>
              <th className="px-4 py-2 text-left">Message</th>
              <th className="px-4 py-2 text-left">Tour Package</th>
              <th className="px-4 py-2 text-left">Date of Booking</th>
            </tr>
          </thead>
          <tbody>
            {reservations.length > 0 ? (
              reservations.map((reservation , index) => (
                <tr key={reservation.id} className="border-t border-gray-200">
                   <td className="px-4 py-2">{index + 1}</td>
                 <td className="px-4 py-2">{reservation.reservation_name}</td>
                  <td className="px-4 py-2">{reservation.reservation_email}</td>
                  <td className="px-4 py-2">{reservation.reservation_phone}</td>
                  <td className="px-4 py-2">{reservation.reservation_message}</td>
                  <td className="px-4 py-2">{reservation.package_id}</td>
                  <td className="px-4 py-2">{new Date(reservation.reservation_date).toLocaleDateString()}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center px-4 py-2 border-t border-gray-200">
                  No reservations found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReservedTickets;
