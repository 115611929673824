import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaTachometerAlt, FaUsers, FaMap, FaCog, FaUser, FaFile, FaInfo } from 'react-icons/fa';

const Sidebar = () => {
  return (
    <div className="bg-white py-4 px-5 w-64 min-h-screen border-r border-gray-200 fixed  top-0 left-0 h-full  z-10">
      <div className="mb-8">
        <div className="text-2xl text-green-800 pb-6" style={{ fontFamily: 'Impact, sans-serif' }}>
          SONG & STARK TOURS
        </div>
        <ul className="space-y-2">
          <li>
            <NavLink
              to="/dashboard"
              className={({ isActive }) =>
                `flex items-center text-base p-2 font-bold ${
                  isActive ? 'text-green-800' : 'text-black'
                } hover:text-green-800`
              }
            >
              <FaTachometerAlt className="text-lg mr-3" /> Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/bookings"
              className={({ isActive }) =>
                `flex items-center text-base p-2 font-bold ${
                  isActive ? 'text-green-800' : 'text-black'
                } hover:text-green-800`
              }
            >
              <FaUsers className="text-lg mr-3" /> Bookings
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/tours"
              className={({ isActive }) =>
                `flex items-center text-base p-2 font-bold ${
                  isActive ? 'text-green-800' : 'text-black'
                } hover:text-green-800`
              }
            >
              <FaMap className="text-lg mr-3" /> Tour Packages
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                `flex items-center text-base p-2 font-bold ${
                  isActive ? 'text-green-800' : 'text-black'
                } hover:text-green-800`
              }
            >
              <FaInfo className="text-lg mr-3" /> Contacts Inquiries
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/users"
              className={({ isActive }) =>
                `flex items-center text-base p-2 font-bold ${
                  isActive ? 'text-green-800' : 'text-black'
                } hover:text-green-800`
              }
            >
              <FaUser className="text-lg mr-3" /> Users
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/reports"
              className={({ isActive }) =>
                `flex items-center text-base p-2 font-bold ${
                  isActive ? 'text-green-800' : 'text-black'
                } hover:text-green-800`
              }
            >
              <FaFile className="text-lg mr-3" /> Reports
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/account"
              className={({ isActive }) =>
                `flex items-center text-base p-2 font-bold ${
                  isActive ? 'text-green-800' : 'text-black'
                } hover:text-green-800`
              }
            >
              <FaCog className="text-lg mr-3" /> Account Settings
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
