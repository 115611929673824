import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';
import { getToken } from '../authService';

const AddDestination = ({ onClose, onAddDestination }) => {
  const [name, setName] = useState('');
  const [imageFile, setImageFile] = useState(null); 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const api_url = `${API_URL}/destination/createDestination`;
  const upload_url = `${API_URL}/upload`; 

  // Function to upload image
  const uploadImage = async () => {
    const formData = new FormData();
    formData.append('image', imageFile);
    
    try {
      const token = getToken();
      const response = await axios.post(upload_url, formData, {
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      
      return response.data.file_name;  
    } catch (err) {
      throw new Error('Image upload failed');
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const token = getToken();
  
      let uploadedImageURL = '';
      if (imageFile) {
        uploadedImageURL = await uploadImage(); 
      }
  
      const formData = {
        destination_name: name,
        destination_image: uploadedImageURL,
         // Ensure the uploaded image URL is passed here
      };
  
      const response = await axios.post(api_url, formData, {
        headers: {
          'Authorization': `${token}`, // Fixed authorization format
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status === 200) {
        setName('');
        setImageFile(null);
        onAddDestination(response.data); // Updated to onAddDestination
        onClose();
      } else {
        throw new Error('Failed to add destination');
      }
    } catch (error) {
      setError('Failed to add destination. Please try again.');
      console.error('Error adding destination:', error.response ? error.response.data : error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]); // Update image state on file change
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h3 className="text-lg font-bold mb-4">Add Destination</h3>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="name">
              Destination Name
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="image">
              Upload Destination Image
            </label>
            <input
              type="file"
              id="image"
              onChange={handleImageChange} // Handle file input change
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-400 text-white px-4 py-2 rounded-lg mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-800 text-white px-4 py-2 rounded-lg"
              disabled={loading}
            >
              {loading ? 'Adding...' : 'Add Destination'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddDestination;
