import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './screens/DashboardPage';
import Bookings from './screens/BookingsPage';
import Users from './screens/Users';
import Reports from './screens/Reports';
import AccountSettings from './screens/AccountSetttings';
import { UserProvider } from './components/UserContext';
import Tours from './screens/ToursPage';
import SignIn from './screens/SignInPage';
import ContactUs from './screens/ContactUs';
import TourDetails from './components/TourDetails';
import { ImageProvider } from './components/ImageContext';
import EditTourForm from './components/EditTour';

function App() {
  return (
    <div>
      <Router>
        <ImageProvider>
          <UserProvider>
            <Routes>
              <Route path="/" element={<SignIn />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/bookings" element={<Bookings />} />
              <Route path="/tours" element={<Tours />} />
              <Route path="/tours/:tourId" element={<TourDetails />} />
              <Route path="/tour-details/:packageId" element={<TourDetails />} />
              <Route path="/edit-tour/:packageId" element={<EditTourForm />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/users" element={<Users />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/account" element={<AccountSettings />} />
              <Route path="/signin" element={<SignIn />} />
            </Routes>
          </UserProvider>
        </ImageProvider>
      </Router>
    </div>
  );
}

export default App;
