import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import TourList from "../components/TourList";
import TourForm from "../components/TourForm";
import Layout from "../components/Layout";

const csvFilePath = require("../assets/tours.csv");

const Tours = () => {
  const [tours, setTours] = useState([]);
  const [selectedTour, setSelectedTour] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchTours = async () => {
      const response = await fetch(csvFilePath);
      const reader = response.body.getReader();
      const result = await reader.read();
      const decoder = new TextDecoder("utf-8");
      const csv = decoder.decode(result.value);
      const parsedData = Papa.parse(csv, { header: true }).data;
      setTours(parsedData);
    };

    fetchTours();
  }, []);

  const handleEdit = (tour) => {
    setSelectedTour(tour);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    const updatedTours = tours.filter((tour) => tour.id !== id);
    setTours(updatedTours);
  };

  const handleSave = (newTour) => {
    if (selectedTour) {
      const updatedTours = tours.map((tour) =>
        tour.id === selectedTour.id ? newTour : tour
      );
      setTours(updatedTours);
    } else {
      setTours([newTour, ...tours]);
    }
    setSelectedTour(null);
    setIsModalOpen(false);
  };

  const openNewTourModal = () => {
    setSelectedTour(null);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedTour(null);
    setIsModalOpen(false);
  };

  return (
    <Layout>
      <div className="container mx-auto pt-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl text-green-800 font-bold">List of Tours</h2>
          <button
            className="bg-green-800 text-white px-4 py-2 rounded-md hover:bg-green-700 transition duration-300"
            onClick={openNewTourModal}
          >
            Add New Tour
          </button>
          
        </div>
        <TourList tours={tours} onEdit={handleEdit} onDelete={handleDelete} />
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-xl">
              <TourForm tour={selectedTour} onSave={handleSave} onClose={closeModal} />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Tours;
