import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';
import { getToken } from '../authService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const api_url = `${API_URL}/package/createPackage`;

const TourForm = ({ onSave, onClose,  }) => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');


  const [formData, setFormData] = useState({
    package_title: '',
    package_summary: '',
    package_image: '',
    package_images: [],
    package_location: '',
    destination_id: '',
    activities: [],
    tour_type: [],
    package_days: '',
    package_nights: '',
    package_price: '',
    package_itinerary: [{ title: '', content: '' }],
    package_inclusions: [],
    package_exclusions: [],
    package_requirements: [],
    package_minage: '',
    package_maxpeople: '',
    free_cancelation: false,
    best_price_guarantee: false,
    is_popular: false,
  });
  const handleChange = async (event) => {
    const { name, value, type, files } = event.target;
  
    if (type === 'file') {
      const file = files[0];
      if (file) {
        try {
          const uploadedFileName = await handleImageUpload(file);
          if (uploadedFileName) {
            setFormData(prevFormData => ({
              ...prevFormData,
              [name]: uploadedFileName,
            }));
          }
        } catch (error) {
          console.error('File upload failed:', error);
          setError('Failed to upload image. Please try again.');
        }
      }
    } else if (name.startsWith('itinerary[')) {
      // Handle itinerary items
      const index = parseInt(name.match(/\d+/)[0], 10);
      const field = name.includes('title') ? 'title' : 'content';
  
      setFormData(prevFormData => {
        const updatedItinerary = [...prevFormData.package_itinerary];
        updatedItinerary[index] = {
          ...updatedItinerary[index],
          [field]: value,
        };
        return {
          ...prevFormData,
          package_itinerary: updatedItinerary,
        };
      });
    } else if (name.endsWith('[]')) {
      const fieldName = name.replace('[]', '');
      setFormData(prevFormData => ({
        ...prevFormData,
        [fieldName]: value.split(',').map(item => item.trim()),
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: type === 'checkbox' ? event.target.checked : value,
      }));
    }
  };
  
  const handleFileChange = async (files) => {
    const fileArray = Array.from(files);  // Convert FileList to Array
    const uploadedFileNames = [];
  
    try {
      // Upload each file concurrently
      const uploadPromises = fileArray.map(file => handleImageUpload(file));
      const results = await Promise.all(uploadPromises); // Wait for all uploads to complete
  
      results.forEach(result => {
        if (result) {
          uploadedFileNames.push(result);  // Add filename to array
        }
      });
  
      // After all files are uploaded, update the formData for `package_images`
      setFormData(prevFormData => ({
        ...prevFormData,
        package_images: uploadedFileNames  // Set uploaded filenames array to formData
      }));
    } catch (error) {
      console.error('Error uploading files:', error);
      setError('Failed to upload images. Please try again.');
    }
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append('image', file);

    try {
      const token = getToken();
      const { data, status } = await axios.post(`${API_URL}/upload`, formData, {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (status === 200) {
        return data.file_name;
      } else {
        throw new Error('Image upload failed');
      }
    } catch (error) {
      console.error('Image upload error:', error);
      setError('Failed to upload image. Please try again.');
    }
  };
  // const handleInputChange = (index, field, value) => {
  //   const updatedItinerary = [...formData.package_itinerary];
  //   updatedItinerary[index][field] = value;
  //   setFormData({ ...formData, package_itinerary: updatedItinerary });
  // };
  const addItineraryItem = () => {
    setFormData({
      ...formData,
      package_itinerary: [...formData.package_itinerary, { title: '', content: '' }]
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccessMessage('');

    try {
      const token = getToken();
      if (!token) {
        throw new Error('No authorization token found.');
      }

      const formattedData = {
        ...formData,
        activities: JSON.stringify(formData.activities),
        best_price_guarantee: formData.best_price_guarantee,
        destination_id: formData.destination_id,
        free_cancelation: formData.free_cancelation,
        is_popular: formData.is_popular,
        package_days: Number(formData.package_days),
        package_exclusions: JSON.stringify(formData.package_exclusions),
        package_image: formData.package_image,
        package_images: JSON.stringify(formData.package_images),
        package_inclusions: JSON.stringify(formData.package_inclusions),
        package_itinerary: JSON.stringify(formData.package_itinerary),
        package_location: formData.package_location,
        package_maxpeople: Number(formData.package_maxpeople),
        package_minage: Number(formData.package_minage),
        package_nights: Number(formData.package_nights),
        package_price: Number(formData.package_price),
        package_requirements: JSON.stringify(formData.package_requirements),
        package_summary: formData.package_summary,
        tour_type: JSON.stringify(formData.tour_type),
      };

      const response = await axios.post(api_url, formattedData, {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setSuccessMessage('Package created successfully!');
        onSave(formattedData);
        resetForm();
      } else {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.response?.data?.message || 'Failed to add package. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      package_title: '',
      package_summary: '',
      package_image: '',
      package_images: [],
      package_location: '',
      destination_id: '',
      activities: [],
      tour_type: [],
      package_days: '',
      package_nights: '',
      package_price: '',
      package_itinerary: [],
      package_inclusions: [],
      package_exclusions: [],
      package_requirements: [],
      package_minage: '',
      package_maxpeople: '',
      free_cancelation: false,
      best_price_guarantee: false,
      is_popular: false,
    });
  };

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  return (
    <div className="relative max-w-3xl mx-auto bg-white shadow-lg rounded-lg flex-grow overflow-y-auto p-4 space-y-4" style={{ maxHeight: 'calc(100vh - 100px)' }}>
      <button
        type="button"
        onClick={onClose}
        className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
      >
        <FontAwesomeIcon icon={faTimes} size="lg" />
      </button>

      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-gray-700">Package Details</h2>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Title</label>
              <input
                type="text"
                name="package_title"
                value={formData.package_title}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Summary</label>
              <textarea
                name="package_summary"
                value={formData.package_summary}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Image (Main)</label>
              <input
                type="file"
                name="package_image"
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div>
           <label className="block text-sm font-medium text-gray-700">Additional Images</label>
            <input
            type="file"
            name="package_images"
            multiple
            accept="image/*"
            onChange={(e) => handleFileChange(e.target.files)} 
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
         </div>
            <div className="flex justify-between">
              <button
                type="button"
                onClick={nextStep}
                className="bg-blue-500 text-white py-2 px-4 rounded"
              >
                Next
              </button>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-gray-700">Location and Tour Type</h2>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Location</label>
              <input
                type="text"
                name="package_location"
                value={formData.package_location}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Destination ID</label>
              <input
                type="text"
                name="destination_id"
                value={formData.destination_id}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Min Age</label>
              <input
                type="number"
                name="package_minage"
                value={formData.package_minage}
                onChange={handleChange}
                className="border rounded p-2 w-full"
                placeholder="Enter minimum age"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Max People</label>
              <input
                type="number"
                name="package_maxpeople"
                value={formData.package_maxpeople}
                onChange={handleChange}
                className="border rounded p-2 w-full"
                placeholder="Enter maximum number of people"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Tour Type (Comma Separated)</label>
              <input
                type="text"
                name="tour_type[]"
                value={formData.tour_type.join(', ')}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div className="flex justify-between">
              <button
                type="button"
                onClick={prevStep}
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded"
              >
                Previous
              </button>
              <button
                type="button"
                onClick={nextStep}
                className="bg-blue-500 text-white py-2 px-4 rounded"
              >
                Next
              </button>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-gray-700">Pricing and Itinerary</h2>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Days</label>
              <input
                type="number"
                name="package_days"
                value={formData.package_days}
                onChange={handleChange}
                className="border rounded p-2 w-full"
                placeholder="Enter number of days"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Nights</label>
              <input
                type="number"
                name="package_nights"
                value={formData.package_nights}
                onChange={handleChange}
                className="border rounded p-2 w-full"
                placeholder="Enter number of nights"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Price</label>
              <input
                type="number"
                name="package_price"
                value={formData.package_price}
                onChange={handleChange}
                className="border rounded p-2 w-full"
                placeholder="Enter price"
              />
            </div>
            <div>
  <label className="block text-sm font-medium text-gray-700">Itinerary</label>
  {formData.package_itinerary.map((item, index) => (
    <div key={index} className="mb-4">
      <div className="mb-2">
        <label className="block text-sm font-medium text-gray-700">Title {index + 1}</label>
        <input
          type="text"
          name={`itinerary[${index}].title`}
          value={item.title}
          onChange={handleChange}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Content {index + 1}</label>
        <textarea
          name={`itinerary[${index}].content`}
          value={item.content}
          onChange={handleChange}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          rows="4"
        />
      </div>
    </div>
  ))}
  <button
    type="button"
    onClick={addItineraryItem}
    className="mt-4 bg-green-500 text-white py-2 px-4 rounded"
  >
    Add Itinerary Item
  </button>
</div>

            <div className="flex justify-between">
              <button
                type="button"
                onClick={prevStep}
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded"
              >
                Previous
              </button>
              <button
                type="button"
                onClick={nextStep}
                className="bg-blue-500 text-white py-2 px-4 rounded"
              >
                Next
              </button>
            </div>
            </div>
        )}

        {step === 4 && (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-gray-700">Inclusions, Exclusions, and Other Details</h2>
            <div>
              <label className="block text-sm font-medium text-gray-700">Inclusions (Comma Separated)</label>
              <input
                type="text"
                name="package_inclusions[]"
                value={formData.package_inclusions.join(', ')}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Exclusions (Comma Separated)</label>
              <input
                type="text"
                name="package_exclusions[]"
                value={formData.package_exclusions.join(', ')}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Requirements (Comma Separated)</label>
              <input
                type="text"
                name="package_requirements[]"
                value={formData.package_requirements.join(', ')}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                name="free_cancelation"
                checked={formData.free_cancelation}
                onChange={handleChange}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm font-medium text-gray-700">Free Cancelation</label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                name="best_price_guarantee"
                checked={formData.best_price_guarantee}
                onChange={handleChange}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm font-medium text-gray-700">Best Price Guarantee</label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                name="is_popular"
                checked={formData.is_popular}
                onChange={handleChange}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm font-medium text-gray-700">Mark as Popular</label>
            </div>

            <div className="flex justify-between">
              <button
                type="button"
                onClick={prevStep}
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded"
              >
                Previous
              </button>
              <button
                type="submit"
                className="bg-green-500 text-white py-2 px-4 rounded"
                disabled={loading}
              >
                {loading ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </div>
        )}
      </form>

      {successMessage && (
        <div className="mt-4 bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
          <p>{successMessage}</p>
        </div>
      )}
      {error && (
        <div className="mt-4 bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default TourForm;
