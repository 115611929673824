// src/pages/ReportsPage.js
import React, { useState } from 'react';
import { FaDownload, FaTrashAlt } from 'react-icons/fa';
import Layout from '../components/Layout';

const ReportsPage = () => {
  const [reportType, setReportType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [userFilter, setUserFilter] = useState('');
  const [locationFilter, setLocationFilter] = useState('');
  const [reports, setReports] = useState([]); // State to hold generated reports

  // Mock function to simulate report generation
  const generateReport = () => {
    if (!reportType || !startDate || !endDate) {
      alert('Please select report type and date range.');
      return;
    }

    // Mock filtered report data
    const newReport = {
      id: reports.length + 1,
      type: reportType,
      date: `${startDate} to ${endDate}`,
      user: userFilter || 'All Users',
      location: locationFilter || 'All Locations',
      fileName: `${reportType}_report_${reports.length + 1}.pdf`,
    };

    setReports([...reports, newReport]);
  };

  // Mock function to simulate downloading a report
  const downloadReport = (fileName) => {
    alert(`Downloading ${fileName}`);
  };

  // Mock function to simulate deleting a report
  const deleteReport = (id) => {
    setReports(reports.filter(report => report.id !== id));
  };

  return (
    <Layout>
    <div className="flex min-h-screen">
     

      {/* Main content area */}
      <div className="flex-1 p-6 bg-gray-100">
        <h1 className="text-2xl font-bold text-green-800 mb-6">Reports</h1>

        {/* Report Generation Controls */}
        <div className="bg-white shadow-md rounded p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">Generate Reports</h2>

          {/* Report Type Selection */}
          <div className="mb-4">
            <label htmlFor="reportType" className="block text-sm font-medium text-gray-700 mb-1">
              Report Type
            </label>
            <select
              id="reportType"
              value={reportType}
              onChange={(e) => setReportType(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="" disabled>Select a report type</option>
              <option value="Booking">Booking</option>
              <option value="Tours">Tours</option>
              <option value="Users">Users</option>
            </select>
          </div>

          {/* Date Range Selection */}
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mb-1">
                Start Date
              </label>
              <input
                type="date"
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mb-1">
                End Date
              </label>
              <input
                type="date"
                id="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
          </div>

          {/* Additional Filters */}
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label htmlFor="userFilter" className="block text-sm font-medium text-gray-700 mb-1">
                User Filter
              </label>
              <input
                type="text"
                id="userFilter"
                value={userFilter}
                onChange={(e) => setUserFilter(e.target.value)}
                placeholder="Enter user name or ID"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label htmlFor="locationFilter" className="block text-sm font-medium text-gray-700 mb-1">
                Location Filter
              </label>
              <input
                type="text"
                id="locationFilter"
                value={locationFilter}
                onChange={(e) => setLocationFilter(e.target.value)}
                placeholder="Enter location"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
          </div>

          {/* Generate Report Button */}
          <button
            onClick={generateReport}
            className="bg-green-800 text-white px-4 py-2 rounded hover:bg-green-900"
          >
            Generate Report
          </button>
        </div>

        {/* Reports Table */}
        <div className="bg-white shadow-md rounded p-6">
          <h2 className="text-xl font-semibold mb-4">Generated Reports</h2>
          {reports.length === 0 ? (
            <p>No reports generated yet.</p>
          ) : (
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b">ID</th>
                  <th className="py-2 px-4 border-b">Type</th>
                  <th className="py-2 px-4 border-b">Date Range</th>
                  <th className="py-2 px-4 border-b">User</th>
                  <th className="py-2 px-4 border-b">Location</th>
                  <th className="py-2 px-4 border-b">Actions</th>
                </tr>
              </thead>
              <tbody>
                {reports.map((report) => (
                  <tr key={report.id}>
                    <td className="py-2 px-4 border-b text-center">{report.id}</td>
                    <td className="py-2 px-4 border-b text-center">{report.type}</td>
                    <td className="py-2 px-4 border-b text-center">{report.date}</td>
                    <td className="py-2 px-4 border-b text-center">{report.user}</td>
                    <td className="py-2 px-4 border-b text-center">{report.location}</td>
                    <td className="py-2 px-4 border-b text-center">
                      <button
                        onClick={() => downloadReport(report.fileName)}
                        className="text-green-800 hover:text-green-900 mx-2"
                      >
                        <FaDownload />
                      </button>
                      <button
                        onClick={() => deleteReport(report.id)}
                        className="text-red-800 hover:text-red-900 mx-2"
                      >
                        <FaTrashAlt />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
    </Layout>
  );
};

export default ReportsPage;
