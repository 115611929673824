import React from 'react';

const ActiveTours = ({ tours }) => {
  const toursArray = Array.isArray(tours) ? tours : [];

  return (
    <div className="mb-8">
      <h2 className="text-2xl text-green-900 font-semibold mb-6">Active Tours</h2>
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        {toursArray.length > 0 ? (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-green-50">
              <tr>
                <th className="px-6 py-3 text-left text-sm font-medium text-green-700 uppercase tracking-wider">
                  Tour Name
                </th>
                <th className="px-6 py-3 text-left text-sm font-medium text-green-700 uppercase tracking-wider">
                  Start Date
                </th>
                <th className="px-6 py-3 text-left text-sm font-medium text-green-700 uppercase tracking-wider">
                  Customers
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {toursArray.map((tour) => (
                <tr key={tour.id} className="hover:bg-green-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                    {tour.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                    {tour.startDate}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                    {tour.customers}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="p-6 text-center">
            <p className="text-gray-500">No active tours at the moment.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActiveTours;