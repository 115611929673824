import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios
import AddActivity from './AddActivity';
import { API_URL } from '../variables';
import { getToken } from '../authService';
import { useImage } from './ImageContext';
const api_url = `${API_URL}/activity/getAllActivities`;

function Activities() {
  const [activities, setActivities] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { getImageUrl } = useImage();


    const fetchActivities = async () => {
      try {
        const token = getToken();
        const response = await axios.get(api_url, {
          headers: {
            'Authorization': `${token}`,
          },
        });
        setActivities(response.data); 
      } catch (error) {
        console.error('Error fetching activities:', error.message);
      }
    };
    useEffect(() => {
    fetchActivities();
  }, []);

  const handleAddActivity = (newActivity) => {
    setActivities([...activities, newActivity]);
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-lg font-bold text-gray-600 mb-4">Activities</h2>
      {activities.length === 0 ? (
        <p className="text-gray-500">No activities added.</p>
      ) : (
        <ul className="space-x-4 flex flex-row" >
          {activities.map(activity => (
            <li key={activity.activity_id} className="flex items-center space-x-4">
              {activity.activity_image ? (
                <img
                src={getImageUrl(activity.activity_image)}
                  alt={activity.activity_name}
                  className="w-24 h-24 object-cover rounded-lg"
                  style={{ maxWidth: '100px', maxHeight: '100px' }} // Adjust size as needed
                />
              ) : (
                <div className="w-24 h-24 bg-gray-200 flex items-center justify-center rounded-lg">
                  <span className="text-gray-500">No Image</span>
                </div>
              )}
              <div className="flex-grow">
                <div className="font-bold">{activity.activity_name}</div>
                <div className="text-sm text-gray-500">Order: {activity.activity_order}</div>
              </div>
            </li>
          ))}
        </ul>
      )}
      <button
        onClick={() => setShowModal(true)}
        className="mt-4 bg-green-800 text-white p-2 rounded-lg"
      >
        Add Activity
      </button>

      {showModal && (
        <AddActivity
          onClose={() => setShowModal(false)}
          onAddActivity={handleAddActivity}
        />
      )}
    </div>
  );
}

export default Activities;
