import React, { useEffect, useState } from 'react';
import { FaSearch, FaEdit ,FaTrashAlt } from 'react-icons/fa';
import AddUser from './AddUser';
import { API_URL } from '../variables';
import { getToken } from '../authService';

const api_url = `${API_URL}/admin/getAdmins`;

const UserTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [admins, setAdmins] = useState([]);

  // Fetch all admins when the component loads
  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const token = getToken();
        const response = await fetch(api_url, {
          method: 'GET',
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch admins');
        }

        const data = await response.json();
        setAdmins(data); // Store fetched admins in state
      } catch (error) {
        alert('Error: ' + error.message);
      }
    };

    fetchAdmins();
  }, []);

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-green-800">Users Dashboard</h2>
      <div className="flex justify-between items-center mb-6">
        <div className="relative w-1/2">
          <FaSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-green-800" />
          <input
            type="text"
            placeholder="Search"
            className="border border-green-800 pl-10 p-2 rounded w-full focus:outline-none focus:ring-2 focus:ring-green-700"
          />
        </div>
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-green-800 text-yellow-300 px-4 py-2 rounded hover:bg-green-600 flex items-center"
        >
          Add user <span className="ml-2 text-xl">+</span>
        </button>
      </div>
      <table className="w-full table-auto border-collapse">
        <thead className="text-green-800">
          <tr>
            <th className="border-b py-3 px-4 text-left">Name</th>
            <th className="border-b py-3 px-4 text-left">Create Date</th>
            <th className="border-b py-3 px-4 text-left">Role</th>
            <th className="border-b py-3 px-4 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          {admins.map((admin) => (
            <tr key={admin.id}>
              <td className="border-b py-3 px-4">{admin.firstName} {admin.lastName}</td>
              <td className="border-b py-3 px-4">{new Date(admin.createdAt).toLocaleDateString()}</td>
              <td className="border-b py-3 px-4">Admin</td>
              <td className="border-b py-3 px-4">
                <button className="text-green-800"><FaEdit /></button>
                <button className="text-yellow-400 ml-3"><FaTrashAlt /></button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for adding a new admin */}
      <AddUser isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

export default UserTable;
