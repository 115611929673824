import React from 'react';
import Layout from '../components/Layout';
import PlannedTrips from '../components/PlannedTrips';
import ReservedTickets from '../components/ReservedTickets';

const Bookings = () => {
  return (
    <Layout>
      <div className="flex justify-center p-6 min-h-screen" style={{ background: 'linear-gradient(135deg, #f7f9fc 0%, #e9eef5 100%)' }}>
        <main className="w-full max-w-6xl">
          <h1 className="text-2xl font-semibold text-green-800 mb-8 text-center">Bookings Overview</h1>

          {/* Navigation Links */}
          <div className="flex justify-center mb-6">
            <a href="#reserved-tickets" className="text-green-800 font-semibold px-4 py-2 border border-green-800 rounded-lg mx-2 hover:bg-green-800 hover:text-white transition duration-300">
              Go to Reserved Tickets
            </a>
            <a href="#planned-trips" className="text-green-800 font-semibold px-4 py-2 border border-green-800 rounded-lg mx-2 hover:bg-green-800 hover:text-white transition duration-300">
              Go to Planned Trips
            </a>
          </div>

          {/* Reserved Tickets Section */}
          <section id="reserved-tickets" className="mb-8">
            <div className="bg-white shadow-lg rounded-lg p-6 mb-4">
              <ReservedTickets />
            </div>
          </section>

          {/* Planned Trips Section */}
          <section id="planned-trips" className="mb-8">
            <div className="bg-white shadow-lg rounded-lg p-6">
              <PlannedTrips />
            </div>
          </section>
        </main>
      </div>
    </Layout>
  );
};

export default Bookings;
